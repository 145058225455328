"use client";

import { Card, CardBody } from "@material-tailwind/react";
import Markdown from "react-markdown";

interface AboutOperatorProps {
  props?: {
    title_operator: string;
    text_operator: string;
  };
}

export default function AboutOperator({ props }: AboutOperatorProps) {
  if (!props) return;
  const { title_operator, text_operator } = props;
  return text_operator ? (
    <Card className="mt-12 mb-11 w-full h-fit">
      <CardBody className="p-0">
        <div className="flex items-center bg-gray-5 w-full h-11 mx-0 rounded-lg">
          <p className="text-white w-full font-2xl font-normal flex justify-center">
            {title_operator}
          </p>
        </div>
        <Markdown className="text-gray-5 font-xs font-normal p-9">{text_operator}</Markdown>
      </CardBody>
    </Card>
  ) : null;
}
