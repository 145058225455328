import React from "react";


interface StarRatingProps {
  rating: number;
  className?: string;
}

const StarRating: React.FC<StarRatingProps> = ({ rating, className }) => {
  // Normalizando a escala para 0.5-5.0 (usando 0.5 incrementos)
  const normalizedRating = Math.max(
    0.5,
    Math.min(5.0, Math.round(rating * 2) / 2)
  );

  const starIcons = Array.from({ length: 5 }, (_, index) => (
    <p
      key={index}
      className={`text-2xl`}
      style={{ color: index + 0.5 <= normalizedRating ? "#e6fa00" : "#666666" }}
    >
      ★
    </p>
  ));

  return (
    <div className={`flex justify-center py-2 ${className || ""}`}>
      {starIcons}
    </div>
  );
};

export default StarRating;
