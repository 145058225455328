"use client";

import { Carousel } from "@/components/MaterialTailwind/MTProvider";
import StarRating from "../AvaliationsAboutUs/StarRating";
import { useEffect, useState } from "react";

interface Avaliation {
  name: string;
  rate: number;
  description: string;
}

interface AvaliationCardProps {
  props?: Avaliation[];
}

export default function AvaliationCardLp({ props }: AvaliationCardProps) {
  const [numCardsToShow, setNumCardsToShow] = useState(3);

  useEffect(() => {
    const handleResize = () => setNumCardsToShow(window.innerWidth >= 768 ? 3 : 1);
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (!props) return null;

  const chunkArray = (arr: any, chunkSize: number) => {
    const result = [];
    for (let i = 0; i < arr.length; i += chunkSize) result.push(arr.slice(i, i + chunkSize));
    return result;
  };

  return chunkArray(props, numCardsToShow).length > 0 && (
    <div className="overflow-hidden relative flex justify-center">
      <Carousel
        autoplay
        loop
        className="rounded-xl max-w-[90vw] mb-12 static"
        nextArrow={() => <></>}
        prevArrow={() => <></>}
      >
        {chunkArray(props, numCardsToShow).map((group: Avaliation[], groupIndex: number) => (
          <div key={groupIndex} className="flex flex-row justify-center gap-4 mb-12 w-full">
            {group.map(({ name, rate, description }: Avaliation, index: number) => (
              <div
                key={index}
                className="max-w-[400px] sm:w-[60%] md:w-[33.33%] lg:w-[26.33%] xl:w-[22.33%] 2xl:w-[24%] flex flex-col bg-gray-5 rounded-2xl overflow-hidden mx-2"
              >
                <div className="flex-grow flex flex-col justify-start">
                  <p className="text-lg text-primary font-semibold pl-6 pb-2 pt-5">{name}</p>
                  <StarRating rating={Math.floor(rate)} className="flex pb-4 self-start pl-6" />
                </div>
                <div className="text-sm text-white font-light px-6 pb-6">{description}</div>
              </div>
            ))}
          </div>
        ))}
      </Carousel>
    </div>
  );
}
