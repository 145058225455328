"use client";

import Image from "next/image";
import Markdown from "react-markdown";

interface CardOperatorImageProps {
  props?: {
    about_text: string;
    about_image: string;
  };
}

export function CardOperatorWithImage({ props }: CardOperatorImageProps) {
  if (!props) return;
  const { about_text, about_image } = props;
  return about_text ? (
    <div className="flex flex-col-reverse md:flex-row bg-gray-5 rounded-2xl w-full h-fit overflow-hidden mt-10 mb-20">
      <div className="flex flex-col">
        <div className="p-6 flex items-center justify-center my-auto">
          <Image
            src={about_image || "/operadaora-banner-onibus.webp"}
            alt="Imagem de um ônibus da viação"
            width={608}
            height={300}
            className="rounded-2xl"
          />
        </div>
      </div>

      <Markdown className="flex-1 min-w-[25rem] text-white text-base font-normal my-auto p-6 ">
        {about_text}
      </Markdown>
    </div>
  ) : null;
}
