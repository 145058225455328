"use client";
import FormSearchCityDesktop from "../../components/forms/FormSearchCityDesktop";
import FormSearchCityMobile from "../../components/forms/FormSearchCityMobile";

interface Props {
  props?: {
    header_title: string;
    header_image: string;
  };
}

export default function BannerLpOperator({ props }: Props) {
  if (!props) return;
  const { header_title, header_image } = props;
  return (
    <>
      {/* Form for mobile */}
      <section className="lg:hidden block bg-gray-5 rounded-b-3xl">
        <div className="flex flex-col justify-start pb-12 md:pb-24 pl-8 pt-12">
          <p className="text-base text-white font-normal">Operadoras</p>
          <p className="pt-8 text-white text-2xl font-bold">{header_title}</p>
        </div>
        <FormSearchCityMobile />
      </section>
      {/* Form for desktop  */}
      <section
        style={{ "--image-url": `url(${header_image || "/operadora-banner-topo.webp"})` } as React.CSSProperties}
        className={
          "hidden lg:flex bg-[image:var(--image-url)] bg-gray-5 bg-no-repeat bg-center bg-cover h-[22rem] mb-20"
        }
      >
        <div className="relative">
          <div className="absolute w-max top-1/4 left-[calc(50vw-36rem)] max-w-6xl mt-10 bg-black rounded-[1.875rem] bg-opacity-40 p-8">
            <h1 className="text-white text-5xl pr-40">{header_title}</h1>
          </div>
        </div>
        <FormSearchCityDesktop heightOption="higher" />
      </section>
    </>
  );
}
